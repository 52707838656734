<template>
  <div id="app">
    <PageHeader />
    <PageLogos />
    <PageSpecial />
    <PageWorks />
    <PageService />
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import PageLogos from './components/PageLogos.vue'
import PageSpecial from './components/PageSpecial.vue'
import PageService from './components/PageService.vue'
import PageWorks from './components/PageWorks.vue'
import PageFooter from './components/PageFooter.vue'
export default {
  name: 'App',
  components: {
    PageHeader, PageLogos, PageSpecial, PageService, PageWorks, PageFooter
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
