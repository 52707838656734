<template>
  <div class="header">

    <div class="mobile-menu">
      <nav role="navigation">
        <div id="menuToggle">
          <input v-model="mobile" type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <li>
              <a @click="scrollMe($event)" href="#section-special">НАШИ УСЛУГИ
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
            <li>
              <a @click="scrollMe($event)" href="#section-works">НАШИ РАБОТЫ
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
            <li>
              <a @click="scrollMe($event)" href="#section-brands">БРЕНДЫ
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
            <li>
              <a @click="scrollMe($event)" href="#section-service">CЕРВИС
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
            <li>
              <a @click="scrollMe($event)" href="#footer">КОНТАКТЫ
                <i class="fa fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div style="
              position: absolute;
              right: 52px;
              text-transform: uppercase;
              font-weight: 600;
              color: white;
              font-size: 20px;
              top: -2px;
          "><i class="fa fa-cog"></i> Poytaxt Service
          </div>
        </div>
      </nav>

    </div>

    <div class="container main-header">
      <div class="header-links">
        <div class="header-links-time">
          ПРИЕМ ЗАЯВОК 8:00-22:00
        </div>
        <div class="header-links-logo">
          <img class="logotype lazyload" src="../assets/remontbg.png">
        </div>
        <div class="header-links-phone">
          <div class="header-links-phone-sub-div">
            <div class="header-links-phone-div">
              <a href="tel:917700033">+998 (91) 770-00-33</a>
              <span>сервисный отдел</span>
            </div>
            <div class="header-links-phone-div">
              <a href="tel:994674444">+998 (99) 467-44-44</a>
              <span>отдел запчастей</span>
            </div>
          </div>
          <div class="header-links-phone-icon">
            <a href="tel:917700033" class="phone-callback">
              <i class="fa fa-phone"></i>
              <!-- <img src="../assets/callback.png" alt=""> -->
            </a>
          </div>
        </div>

      </div>

      <div class="header-menu">
        <ul class="header-menu-ul">
          <li class="header-menu-li">
            <a @click="scrollMe($event)" href="#section-special">НАШИ УСЛУГИ</a>
          </li>
          <li class="header-menu-li">
            <a @click="scrollMe($event)" href="#section-works">НАШИ РАБОТЫ</a>
          </li>
          <li class="header-menu-li">
            <a @click="scrollMe($event)" href="#section-brands">БРЕНДЫ</a>
          </li>
          <li class="header-menu-li">
            <a @click="scrollMe($event)" href="#section-service">КОНТАКТЫ</a>
          </li>
          <li class="header-menu-li">
            <a @click="scrollMe($event)" href="#footer">CЕРВИС</a>
          </li>
        </ul>
      </div>

      <div class="header-content">
        <div class="header-content-title">
          <img src="../assets/remontbg.png">
          <br>
          Сервисный центр котлов
          <br>
          Ташкент
        </div>
        <div class="header-content-text">
          Ремонт / Обслуживание / Запчасти
        </div>
        <div class="header-content-btns">
          <a href="tel:917700033" class="header-content-btn"> Оставить заявку </a>
          <a href="tel:994674444" class="header-content-btn"> Каталог запчастей </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'PageHeader',
  data() {
    return {
      mobile: false
    }
  },
  methods: {
    scrollTop: function () {

    },
    scrollMe(anchor) {
      console.log(anchor.srcElement.hash)
      this.mobile = false
      console.log(this.mobile)
    }
  }
}
</script>

<style></style>
