import work1 from "../assets/works/work1.jpg";
import work2 from "../assets/works/work2.jpg";
import work3 from "../assets/works/work3.jpg";
import work4 from "../assets/works/work4.jpg";
import work5 from "../assets/works/work5.jpg";
// import work6 from "../assets/works/work6.jpg";
import work7 from "../assets/works/work7.jpg";
// import work8 from "../assets/works/work8.jpg";
import work9 from "../assets/works/work9.jpg";
import work10 from "../assets/works/work10.jpg";
import work11 from "../assets/works/work11.jpg";
import work12 from "../assets/works/work12.jpg";

const works = [
  {
    src: work1,
    thumbnail: work1,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
  {
    src: work2,
    thumbnail: work2,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
  {
    src: work3,
    thumbnail: work3,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
  {
    src: work4,
    thumbnail: work4,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
    {
      src: work5,
      thumbnail: work5,
      h: 0,
      w:0,
      alt: 'some numbers on a grey background'
    },
//   {
//     src: work6,
//     thumbnail: work6,
//     h: 0,
//     w: 0,
//     alt: "some numbers on a grey background",
//   },
  {
    src: work7,
    thumbnail: work7,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
//   {
//     src: work8,
//     thumbnail: work8,
//     h: 0,
//     w: 0,
//     alt: "some numbers on a grey background",
//   },
  {
    src: work9,
    thumbnail: work9,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
  {
    src: work10,
    thumbnail: work10,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
  {
    src: work11,
    thumbnail: work11,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
  {
    src: work12,
    thumbnail: work12,
    h: 0,
    w: 0,
    alt: "some numbers on a grey background",
  },
];

export default works;
