<template>
  <div class="section-footer" id="footer">
    <div class="container main-footer">
      <div class="footer-links-sub">
        <div class="footer-links-phone">
          <div class="footer-links">
            <h1>
              Контакты
            </h1>
            <div class="footer-link">
              <a href="tel:917700033">+998 (91) 770-00-33</a>
              <span>сервисный отдел</span>
            </div>
            <div class="footer-link">
              <a href="tel:994674444">+998 (99) 467-44-44</a>
              <span>отдел запчастей</span>
            </div>
          </div>
          <div class="footer-links-phone-icon">
            <a href="tel:917700033" class="header-content-btn">
              Заказать звонок
            </a>
          </div>
        </div>
        <div class="footer-text-div">
          <p>
            Компания «Poytaxt Service» Ташкент работает на узбекском рынке отопления. Мы предлагаем полный спектр услуг
            от проектирования котельной до дальнейшего ежегодного обслуживания и ремонтных работ для газовых и
            электрических котлов.
          </p>
          <p>
            Сайт и все цены, представленные на нем, носят информационный характер и не являются публичной офертой.
          </p>
        </div>
      </div>
    </div>
    <div class="main-footer-sup"> &copy; Poytaxt Service Tashkent. 2023</div>
  </div>
</template>


<script>
export default {
  name: 'PageFooter',
  components: {

  },
  data() {
    return {

    }
  }
}
</script>
<style></style>
