<template>
    <div id="section-special">
        <div class="container special-section">
            <div class="section-header">
                <div class="section-title">Отопление - наша специализация</div>
                <div class="section-subtitle">Уже более 2 лет мы занимаемся системами отопления и знаем о них все</div>
            </div>
            <div class="specials">
                <div class="special">
                    <div class="spec-img">
                        <img alt="Заявка" src="../assets/kak/2.webp">
                    </div>
                    <div class="special-main">
                        <div class="special-title">Ремонт котлов</div>
                        <div class="special-subtitle">Авторизованный СЦ по ремонту котлов и котельного оборудования любой
                            сложности.</div>
                    </div>
                </div>
                <div class="special">
                    <div class="spec-img">
                        <img alt="Заявка" src="../assets/kak/3.webp">
                    </div>
                    <div class="special-main">
                        <div class="special-title">Монтаж котельных
                        </div>
                        <div class="special-subtitle">Монтаж и замена котлов. Монтаж котельных "под ключ". Модернизация
                            котельных.</div>
                    </div>
                </div>
                <div class="special">
                    <div class="spec-img">
                        <img alt="Заявка" src="../assets/kak/1.webp">
                    </div>
                    <div class="special-main">
                        <div class="special-title">Сервисное обслуживание</div>
                        <div class="special-subtitle">Подготовка к отопительному сезону. Модернизация котельных.</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
 
<script>
export default {
    name: 'PageSpecial',
}
</script>
 
<style></style>