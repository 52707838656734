import Vue from 'vue'
import App from './App.vue'
import VuePictureSwipe from 'vue-picture-swipe';



require('./assets/styles/normalize.css')
require('./assets/styles/main.css')
require('./assets/styles/header.css')
require('./assets/styles/logos.css')
require('./assets/styles/special.css')
require('./assets/styles/service.css')
require('./assets/styles/footer.css')

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')



Vue.component('vue-picture-swipe', VuePictureSwipe);
