<template>
    <div class="section-service" id="section-service">
        <div class="section-header container">
            <div class="section-title">Как мы работаем</div>
            <div class="land_scheme">
                <div class="land_scheme-block">
                    <div class="land_scheme-img"><img alt="Заявка" src="../assets/kak/1.webp"></div>

                    <div class="service-infos"><span>Вы оставляете заявку</span>
                        На сайте или по телефону
                    </div>
                    <a class="button js-panel-fixed-btn lf_but" href="tel:917700033">Оставить заявку</a>
                </div>

                <div class="land_scheme-block">
                    <div class="land_scheme-img"><img alt="Мастер" src="../assets/kak/2.webp"></div>

                    <div class="service-infos"><span>Приезжает мастер</span>
                        Проводит диагностику котла и определяет неисправность</div>
                </div>

                <div class="land_scheme-block">
                    <div class="land_scheme-img"><img alt="Ремонт" src="../assets/kak/3.webp"></div>

                    <div class="service-infos"><span>Производит ремонт</span>
                        Необходимое оборудование и запчасти у мастера в наличии</div>
                </div>

                <div class="land_scheme-block">
                    <div class="land_scheme-img"><img alt="Готовность" src="../assets/kak/4.webp"></div>

                    <div class="service-infos"><span>Ваш котёл готов</span>
                        Принимаете работу, получаете квитанцию и гарантию до 12 месяцев</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'PageService',
}
</script>

<style></style>