<template>
  <div class="container section-brands" id="section-brands">
    <div class="section-header">
      <div class="section-title">Бренды</div>
      <div class="section-subtitle">Ремонтируем все бренды котлов</div>
    </div>
    <div class="logos">
      <div class="logos-img"><img src="../assets/brands/vaillant.webp" alt=""></div>
      <div class="logos-img"><img src="../assets/brands/baxi.webp" alt=""></div>
      <div class="logos-img"><img src="../assets/brands/bosch.webp" alt=""></div>
      <div class="logos-img"><img src="../assets/brands/buderus.webp" alt=""></div>
      <div class="logos-img"><img src="../assets/brands/protherm.webp" alt=""></div>
      <div class="logos-img"><img src="../assets/brands/hair.png" alt=""></div>
      <div class="logos-img"><img src="../assets/brands/viesmann.webp" alt=""></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLogos',
}
</script>

<style></style>