<template>
  <div class="container section-works" id="section-works">
    <div class="section-header">
      <div class="section-title">Наши Работы</div>
      <div class="section-subtitle">Убедитесь в качестве нашей работы</div>
    </div>
    <vue-picture-swipe :options="options" :items="items" />
  </div>
</template>


<script>
import works from '../assets/photos'
import VuePictureSwipe from 'vue-picture-swipe'
export default {
  name: 'PageService',
  components: {
    VuePictureSwipe
  },
  props: {
    options: {
      type: Object,
      required: false,
      default() {
        return {
          bgOpacity: 0.95,
          shareEl: false,
          zoomEl: true,
        };
      },
    },
  },
  data() {
    return {
      items: works
    }
  }
}
</script>
<style>
.my-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20;
}

.my-gallery figure {
  font-size: 100px;
}

.my-gallery a img {
  width: 160px;
  height: auto;
}
</style>
